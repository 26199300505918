// src/Footer.js
import { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import logo from '../assets/logo/logowhite.svg';
import './Footer.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import PrivacyPolicy from "../legal/privacy/privacyPolicy";
import Disclaimer from '../legal/disclaimer/disclaimer';
import TermsAndConditions from "../legal/termsandconditions/tnc";


const Footer = () => {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isInView, setIsInView] = useState(false); // Track if footer is in view

  const openPrivacyModal = () => setIsPrivacyModalOpen(true);
  const closePrivacyModal = () => setIsPrivacyModalOpen(false);

  const openDisclaimerModal = () => setIsDisclaimerModalOpen(true);
  const closeDisclaimerModal = () => setIsDisclaimerModalOpen(false);

  const openTermsModal = () => setIsTermsModalOpen(true);
  const closeTermsModal = () => setIsTermsModalOpen(false);


  useEffect(() => {
    // IntersectionObserver setup
    const footerElement = document.querySelector('.footer');
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true); // Trigger animation when footer is in view
        }
      },
      { threshold: 0.7 } // Trigger when 50% of the footer is in the viewport
    );
    observer.observe(footerElement);
    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);


  return (
    <footer className={`footer ${isInView ? 'animate-text' : ''}`}>
      <div className="footer-container">
        {/* Logo and Brand Name */}
        <div className="footer-logo">
          <HashLink to="/#home">  
            <img src={logo} alt="CAPITAFLOW" className="logo" />
          </HashLink>
          <p className={`logo-text ${isInView ? 'fade-in' : ''}`}>
            a suite for your Business needs...
          </p> {/* Apply animation when in view */}
        </div>

        {/* Site Information */}
        <div className="footer-section site-info">
          <h3>Site Information</h3>
          <div>
            <p><HashLink to="/#about">About us</HashLink></p>
            <p><HashLink to="/investors#investorRelations">Investors Relations</HashLink></p>
            <p onClick={openPrivacyModal}>Privacy Policy</p>
            <p onClick={openDisclaimerModal}>Disclaimer</p>
            <p onClick={openTermsModal}>Terms and Conditions</p>
          {/* Modals */}
          <PrivacyPolicy isOpen={isPrivacyModalOpen} onClose={closePrivacyModal} />
          <Disclaimer isOpen={isDisclaimerModalOpen} onClose={closeDisclaimerModal} />
          <TermsAndConditions isOpen={isTermsModalOpen} onClose={closeTermsModal} />
          </div>
        </div>

        {/* Social Media Icons */}
        <div className="footer-section follow-us">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href={process.env.REACT_APP_LINKEDIN_URL} aria-label="LinkedIn"><i className="fab fa-linkedin-in"></i></a>
            <a href={process.env.REACT_APP_INSTAGRAM_URL} aria-label="Instagram"><i className="fab fa-instagram"></i></a>
            <a href={process.env.REACT_APP_TWITTER_URL} aria-label="Twitter"><i className="fa-brands fa-x-twitter"></i></a>
            <a href={process.env.REACT_APP_FACEBOOK_URL} aria-label="Facebook"><i className="fab fa-facebook-f"></i></a>
          </div>
        </div>

        {/* Contact Information */}
        <div className="footer-section contact-us">
          <h3>Address</h3>
          <p>Building number 389, Mayuri Nagar</p>
          <p>Miyapur, Hyderabad</p>
          <p>Telangana, 500049</p>
        </div>
      </div>
      <p className="footer-credit">© 2025 Capitaflow. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
