  import  { useState } from 'react';
  import './Home.css';
  import Welcome from './Welcome';
  import About from './About';
  import Values from './Values';
  import bannerVideo from '../assets/banner/CapitaFlowBusiness.mp4';
  import ContactForm from '../contact/contactForm';
  import useLazyLoad from '../scripts/useLazyLoad';
  import CoverAnimation from '../animations/coverAnimation/CoverAnimation';

  function Home(){
    useLazyLoad(); // Initialize lazy loading
    const [showContactForm] = useState(true); // Example condition
    const [showCoverAnimation] = useState(true); // Example condition
    const [videoLoaded, setVideoLoaded] = useState(false); // Track if video has loaded

    const handleVideoLoad = () => {
      setVideoLoaded(true); // Video has successfully loaded
    };

    return (
      <div className="home-container">
        <section className="banner" id="home">
          <video 
            src={bannerVideo} 
            autoPlay 
            loop 
            muted 
            playsInline
            onCanPlayThrough={handleVideoLoad} 
            className={`banner-video ${videoLoaded ? '' : 'hidden'}`}
          >
            Your browser does not support the video tag.
          </video>
          {/* Text always visible and animating on top of the video */}
          <div>
            {showCoverAnimation && <CoverAnimation />}
          </div>
        </section>

        <Welcome/>

        <About/>
        
        <Values/>
    
        <div>
            {showContactForm && <ContactForm />}
        </div>
        
      </div>
    );
  };

  export default Home;
