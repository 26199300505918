import './Home.css';
import resilienceImg from '../assets/values/resilience.png';
import excellenceImg from '../assets/values/excellence.png'
import visionImg from '../assets/values/visionImg.png';
import integrityImg from '../assets/values/integrity.png';
import serviceImg from '../assets/values/serviceImg.png'; 
import evolveImg from '../assets/values/evolveImg.png'

function Values(){
    return (
        <div>
             <section className="values">
          <h2>Our Values and Principles - REVISE</h2>
          <div className="feature-list">
            <div className="feature-item">
              <img src={resilienceImg} alt="ValuesImg"/>
              <h3>Resilience</h3>
              <p>We navigate challenges with agility, adapting quickly to changing circumstances and bouncing back stronger to ensure continuous progress.
              </p>
            </div>
            <div className="feature-item">
              <img src={excellenceImg} alt="ValuesImg"/>
              <h3>Excellence</h3>
              <p>Our commitment to excellence drives us to achieve the highest quality standards in every project, delivering superior solutions and exceptional value.</p>
            </div>
            <div className="feature-item">
              <img src={visionImg} alt="ValuesImg"/>
              <h3>Vision</h3>
              <p>We guide our strategy with a clear vision, focusing on long-term goals and anticipating market trends to stay ahead in a competitive landscape.</p>
            </div>
            <div className="feature-item">
              <img src={integrityImg} alt="ValuesImg"/>
              <h3>Integrity</h3>
              <p>We build trust through unwavering honesty and ethical practices, ensuring transparency in all our business dealings and fostering strong relationships.</p>
            </div>
            <div className="feature-item">
              <img src={serviceImg} alt="ValuesImg"/>
              <h3>Service</h3>
              <p>Our dedication to outstanding service means providing exceptional support, personalised care, and a client-first approach to meet and exceed expectations.</p>
            </div>
            <div className="feature-item">
              <img src={evolveImg} alt="ValuesImg"/>
              <h3>Evolve</h3>
              <p>We embrace continuous growth and innovation, always learning and adapting to new ideas, technologies, and challenges to stay ahead of the curve.</p>
            </div>
          </div>
        </section>
        </div>
    )
}

export default Values;

