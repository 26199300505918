import './Home.css';

function Welcome(){
    return (
        <div>
            <section className="welcome">
                <h4>We want to revolutionize and streamline your business with our innovative <i>Business Management Suite</i>. 
                Our scalable platform integrates operations and customer interactions, 
                streamlining processes and driving growth for businesses of all sizes. Adaptable and efficient, we empower
                    companies to stay competitive and succeed in any market.
                </h4>
            </section>
        </div>
    )
}

export default Welcome;

