import { useState, useEffect } from "react";
import ArrowUp from '../assets/arrow/top_arrow.svg';
import "./ScrollToTop.css"; // Ensure this file exists

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [bottomOffset, setBottomOffset] = useState(20);
  const [opacity, setOpacity] = useState(0); // Opacity state

  useEffect(() => {
    const checkScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const footer = document.querySelector("footer");

      // Adjust button position if reaching footer
      if (footer) {
        const footerTop = footer.offsetTop;
        const footerHeight = footer.offsetHeight;
        if (scrollPosition + windowHeight > footerTop) {
          setBottomOffset(footerHeight + 20);
        } else {
          setBottomOffset(20);
        }
      }

      // Opacity logic: smoothly increase opacity as user scrolls down
      if (scrollPosition > 200) {
        setIsVisible(true);
        setOpacity(Math.min(1, (scrollPosition - 200) / 500)); // Fade in effect
      } else {
        setOpacity(0);
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, []);

  return (
    <button
      className={`scroll-to-top ${isVisible ? "show" : ""}`}
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      style={{
        bottom: `${bottomOffset}px`,
        opacity: opacity, // Dynamic opacity based on scroll
      }}
    >
      <img src={ArrowUp} alt="Scroll to top" className="scroll-icon" />
    </button>
  );
};

export default ScrollToTop;
