import './Home.css';
import teamImg from '../assets/about/team.png';
import investorImg from '../assets/about/investor.png';
import futureisticImg from '../assets/about/futuristicSystems.png';

function About(){
    return (
        <div>
                   <section className="about" id="about">
          <h2>About Us</h2>
          <div className="feature-list">
            <div className="feature-item">
              <img src={teamImg} alt="teamImage" className="Img" />
              <div>
                <h3>Our Team</h3>
                <p>Our team is a group of passionate innovators committed to reshaping business operations. With diverse expertise across technology and industry, we collaborate to deliver cutting-edge solutions that drive efficiency, growth, and success for our clients.
                  We believe in a collaborative, agile approach where each team member brings unique skills and perspectives to the table. From experienced developers and data scientists to strategic thinkers and business leaders, our team is united by a shared vision of transforming businesses through innovation.</p>
              </div>
            </div>
            <div className="feature-item">
            <img src={investorImg} alt="investorImg" className="Img" />
              <div>
                <h3>Investment Opportunities</h3>
                <p><i>CapitaFlow</i> is redefining the way businesses manage their operations by building a scalable, modular platform that integrates seamlessly across industries. As we continue to develop our innovative solution, we are poised to address the growing demand for streamlined business management tools. This presents a unique opportunity for investors to get in at the ground level of a product that is designed to evolve with the needs of modern enterprises.</p>
                </div>
            </div>
            <div className="feature-item">
              <img src={futureisticImg} alt="futureisticImg" className="Img" />
              <div>
              <h3>Future With Us!</h3>
              <p>Partnering with <i>CapitaFlow</i> opens doors to a future of growth and innovation. As we advance together, you'll benefit from our cutting-edge solutions and personalized approach. We're committed to evolving with your needs, offering ongoing support, and creating opportunities for mutual success. Join us in shaping the future and experience the advantages of a collaborative partnership where your goals drive our innovation.</p>
              </div>
            </div>
          </div>
        </section>
        </div>
    )
}

export default About;